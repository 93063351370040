<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Loader :loader_flag="loader_flag" />

  <div
    class="relative lg:w-full lg:mb-0 mb-4 wage_wrapper-select2"
    style="border-radius: 100px; position: relative"
  >
    <input
      @keydown="filter"
      @keyup="filter"
      v-model="filter_name"
      placeholder="Search for names..."
      class="
        wage_search_field
        w-full
        h-10
        rounded-full
        p-2
        focus:border-gray-400
      "
    />
    <button
      @click="set_clear"
      class="p-2 pt-2 ml-2 w-9 rounded-2xl text-center btn-cancel"
    >
      <img
        v-if="filter_name !== ''"
        class="w-4"
        :src="require('../../assets/images/clear.png')"
      />
      <img
        v-if="filter_name === ''"
        class="w-4"
        :src="require('../../assets/images/search.png')"
      />
    </button>
    <!-- <button  @click="set_filter" class="p-2  rounded-2xl w-9 text-center btn-search"><img class="w-8" :src="require('../../assets/images/search.png')" /></button> -->
  </div>
  <ul class="divide-y divide-gray-100 px-4 py-2">
    <li
      v-for="contact in contact_data_filter"
      :key="contact.username"
      class="py-4 flex"
    >
      <div class="mr-4 self-center">
        <div class="float-right flex">
          <button
            @click="
              openModal(
                contact.favourite_id,
                contact.photo,
                user_detail.user_type === 2 ? contact.company : contact.username
              )
            "
            type="button"
            class="text-gray-600 hover:text-emerald"
          >
            <MailIcon
              class="
                p-1
                rounded-full
                border
                cursor-pointer
                border-solid border-gray-300
                w-8
              "
            >
            </MailIcon>
          </button>
        </div>
      </div>

      <img
        @click="viewProfile(contact.favourite_id)"
        class="h-10 w-10 rounded-full"
        :src="contact.photo"
        :alt="contact.photo"
      />
      <div class="ml-3 self-center">
        <p
          @click="viewProfile(contact.favourite_id)"
          class="text-sm font-medium text_username"
        >
          {{ user_detail.user_type === 2 ? contact.company : contact.username }}
        </p>
        <!-- <p class="text-sm break-all">{{ contact.email }}</p> -->
      </div>
      <div class="ml-auto self-center">
        <div class="float-right flex">
          <button
            @click="removeFavourite(contact.id)"
            type="button"
            class="text-gray-600 hover:text-emerald"
          >
            <XIcon
              style="color: #ea8d8d"
              class="p-1 rounded-full border cursor-pointer w-8"
            >
            </XIcon>
          </button>
        </div>
      </div>
    </li>
  </ul>
  <p class="text-center" v-if="contact_data_filter.length === 0">
    You have no recent contacts
  </p>
  <div class="pagination_div">
    <v-pagination
      v-model="page"
      :pages="Math.ceil(this.contact_data_filter.length / 10)"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="updateHandler"
    />
  </div>
</template>

<script>
import { MailIcon, XIcon } from "@heroicons/vue/solid";
import { mapGetters, mapActions } from "vuex";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {
  APIGetCandidateFavourite,
  APIRemoveCandidatefavourite,
} from "@/api/candidate/api";
import {
  APIGetEmployerFavourite,
  APIRemoveEmployerfavourite,
} from "@/api/employer/api";
import Loader from "@common/Loader.vue";

export default {
  components: {
    MailIcon,
    VPagination,
    XIcon,
    Loader,
  },
  data() {
    return {
      page: 1,
      contact_data: [],
      contact_data_filter: [],
      filter_name: "",
      loader_flag: false,
    };
  },
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
    }),
  },
  methods: {
    ...mapActions({
      fetchUserReliability: "candidate/fetchUserReliability",
      fetchMessageThread: "candidate/fetchMessageThread",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchtSetMessageModalConfigs: "candidate/fetchtSetMessageModalConfigs",
      fetchSetEmployerModalConfigs: "candidate/fetchSetEmployerModalConfigs",
    }),
    viewProfile(userid) {
      this.fetchSetEmployerModalConfigs({ user_id: userid });
      const data = {
        isOpen: true,
        iconName: "UserIcon",
        componentName:
          this.user_detail.user_type === 3
            ? "CandidateProfile"
            : "EmployerProfile",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
    },
    updateHandler() {
      this.contact_data_filter = [];
      for (let i = 0; i < this.contact_data.length; i++) {
        if (this.user_detail.user_type === 2) {
          if (
            this.contact_data[i]["company"]
              .toLowerCase()
              .includes(this.filter_name.toLowerCase())
          ) {
            this.contact_data_filter.push(this.contact_data[i]);
          }
        }
        if (this.user_detail.user_type === 3) {
          if (
            this.contact_data[i]["username"]
              .toLowerCase()
              .includes(this.filter_name.toLowerCase())
          ) {
            this.contact_data_filter.push(this.contact_data[i]);
          }
        }
      }
      console.log("contact_data_filter", this.contact_data_filter);
    },
    openModal(user_id, photo, username) {
      this.fetchUserReliability({ id: 1 });
      const message_data = {
        photo: photo,
        reply_id: "",
        user_id: user_id,
        subject: "",
        user_name: username,
      };
      const data = {
        isOpen: true,
        iconName: "UserPhoto",
        componentName: "NetworkModalContent",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
      this.fetchtSetMessageModalConfigs(message_data);
    },

    filter() {
      this.updateHandler();
    },
    set_clear() {
      this.filter_name = "";
      this.updateHandler();
    },
    removeFavourite(id) {
      this.$swal({
        title: "Remove this favourite",
        text: "Are you sure you want to remove this favourite?",
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#049A59",
        cancelButtonColor: "rgba(255,0,0,0.7)",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loader_flag = true;
          if (this.user_detail.user_type === 2) {
            APIRemoveCandidatefavourite(id).then((res) => {
              this.loader_flag = false;
              if (res.data.success) {
                this.$swal({
                  title: "Successfully submitted",
                  icon: "success",
                  confirmButtonText:
                    '<i class="fa fa-close"></i> Close this window',
                  customClass: {
                    confirmButton: "SuccessClosebutton",
                  },
                });
              } else {
                this.$swal({
                  title: "Oops!",
                  icon: "error",
                  text: res.data.message,
                  confirmButtonText: "Try again",
                  customClass: {
                    confirmButton: "ErrorClosebutton",
                  },
                });
              }
            });
          }
          if (this.user_detail.user_type === 3) {
            APIRemoveEmployerfavourite(id).then((res) => {
              this.loader_flag = false;
              if (res.data.success) {
                this.get_favourite();
                this.$swal({
                  title: "Successfully submitted",
                  icon: "success",
                  confirmButtonText:
                    '<i class="fa fa-close"></i> Close this window',
                  customClass: {
                    confirmButton: "SuccessClosebutton",
                  },
                });
              } else {
                this.$swal({
                  title: "Oops!",
                  icon: "error",
                  text: res.data.message,
                  confirmButtonText: "Try again",
                  customClass: {
                    confirmButton: "ErrorClosebutton",
                  },
                });
              }
            });
          }
        }
      });
    },

    get_favourite() {
      if (this.user_detail.user_type === 2) {
        APIGetCandidateFavourite().then((res) => {
          this.contact_data = res.data.results;
          this.updateHandler();
        });
      }
      if (this.user_detail.user_type === 3) {
        APIGetEmployerFavourite().then((res) => {
          this.contact_data = res.data.results;
          this.updateHandler();
        });
      }
    },
  },
  mounted() {
    this.get_favourite();
  },
};
</script>
